// import moment from "moment";

export default function(list,valueName=[],type="normal") {
    // function mGetDate() {
    //     var date = new Date();
    //     var year = date.getFullYear();
    //     var month = date.getMonth()+1;
    //     var d = new Date(year, month, 0);
    //     return d.getDate();
    // }
    // let config={
    //     freeMaintenanceQuantity:"免保台量",
    //     remoteMonitorQuantity:"可遥监台量",
    //     onlineQuantity:"在线台量",
    //     todayRepairQuantity:"今日急修台量",
    //     todayMaintenanceQuantity:"今日保养台量",
    //     inMaintenanceQuantity:"在保台量",
    //     regularMaintenanceQuantity:"安规保养台量",
    //     asNeededMaintenanceQuantity:"安需保养台量",
    //     // totalQuantity:"总计",
    // };
    if (type==="bar") {
        let arr=[];
        let option = {
            xAxisData:[],
        };
        for (let i = 0; i < list.length; i++) {
            let obj={};
            for (let [key,value] of Object.entries(list[i])) {
                if (key.toLocaleLowerCase().indexOf("name")>-1) {
                    obj.name=value;
                }else if (key.toLocaleLowerCase().indexOf("id")>-1||key.toLocaleLowerCase().indexOf("code")>-1) {
                    obj.id=value;
                }
            }
            let count =0;
            for (let j = 0; j < valueName.length; j++) {
                count+=parseInt(list[i].totalQuantity,10);
            }
            option.xAxisData.push(obj.name);
            arr.push(count);
        }


        return {arr,option};
    }else if (type==="sunburst") {
        const sortList = list.sort(item=>item.totalQuantity);
        const dix =4;
        let arr =[];
        sortList.forEach((item,index)=>{
            if (index<dix) {
                arr.push({
                    name:item.brandName,
                    id:item.districtCode,
                    value:item.totalQuantity,
                });
            }else if (index===dix) {
                arr.push({
                    name:"其他",
                    id:Math.random(),
                    value:item.totalQuantity,
                });
            }else {
                arr[dix].value=arr[dix].value+item.totalQuantity;
            }


            // let obj={children:[]};
            // for (let [key,value] of Object.entries(item)) {
            //     if (key.toLocaleLowerCase().indexOf("name")>-1) {
            //         obj.name=value;
            //     }else if (key.toLocaleLowerCase().indexOf("id")>-1||key.toLocaleLowerCase().indexOf("code")>-1) {
            //         obj.id=value;
            //     }
            // }
            // for (let [key,value] of Object.entries(item)) {
            //     // console.log(62,)
            //     if (config[key]) {
            //         obj.children.push({
            //             name:config[key],
            //             value,
            //         });
            //     }
            //
            // }
            // if (index<dix) {
            //     arr.push(obj);
            // }else if (index===dix) {
            //     arr.push({name:"其他",children: []});
            //     arr[dix].children=obj.children;
            // }else {
            //     obj.children.forEach(item=>{
            //         for (let i = 0; i < arr[dix].children.length; i++) {
            //             if (item.name===arr[dix].children[i].name) {
            //                 arr[dix].children[i].value=arr[dix].children[i].value+item.value;
            //             }
            //         }
            //     });
            //
            //
            // }

        });
        return {arr};
    }else if (type==="axisBar") {


        const arr =[{name:"普通停梯数量",data:new Array(list.length).fill(0)},{name:"困人数量",data:new Array(list.length).fill(0)}];
        let option = {
            xAxisData:[],
            legendData:["普通停梯数量","困人数量"],
        };

        // const arr =[];
        list.forEach((item,index)=>{
            option.xAxisData.push(item.year+"."+item.month);
            for (let[key,value] of Object.entries(item)) {
                if (key==="elevatorStoppedSum") {
                    arr.push();
                    arr[0].data[index]=value;
                }else if (key==="peopleTrappedSum") {
                    arr[1].data[index]=value;
                }
            }
        });
        return {arr,option};
    }else if (type==="pie") {
        const arr =[];
        if (list.brandQuantities) {

            list.brandQuantities.forEach(item=>{
                arr.push({
                    name:item.districtName,
                    id:item.districtCode,
                    value:item.totalQuantity,
                });
            });
        }else {
            list.forEach(item=>{
                arr.push({
                    name:item.districtName,
                    id:item.districtCode,
                    value:item.totalQuantity,
                });
            });
        }

        return {arr};
    }else if (type==="scatter") {
        const arr = [];
        const yaxisList=[];
        const option={yaxis:[]};
        list.forEach(item=>{
            if (!option.yaxis.includes(item.brandName)||!yaxisList.includes(item.brandId)) {
                option.yaxis.push(item.brandName);
                yaxisList.push(item.brandId);
            }
            let count =yaxisList.indexOf(item.brandId);
            arr.push([count,item.elevatorAge,item.quantity?item.quantity:10]);
        });
        return {arr,option};
    }else {
        let arr=[];

        list.map(item=>{
            let count =item.totalQuantity;

            for (let [key,value] of Object.entries(item)) {
                if (key.toLocaleLowerCase().indexOf("name")>-1) {
                    item.name=value;
                }else if (key.toLocaleLowerCase().indexOf("id")>-1||key.toLocaleLowerCase().indexOf("code")>-1) {
                    item.id=value;
                }
            }
            arr.push({name:item.name,id:item.id,value:count});
        });
        return {arr};
    }

}

<template>
  <div id="app">
    <china-map
        :key="key"
        cascade
        :cityMapName="山东"
        :echartsData="testData"
        :scatterData="scatterData"
        @mapClick="mapClick"
    />
  </div>
</template>

<script>
import chinaMap from "./chinaMap.vue";
import format from "@/util/bisScreen";
import  {getRegionCode} from "./mapDict";
export default {
  name: "App",
  components: {
    chinaMap,
  },
  data() {
    return {
      key: 0,
      testData: [

        {
          name: "威海",
          value: 83095.9,
        },

      ],
      scatterData: [],
    };
  },
  created () {
    // this.echartsData = this.testData.map(({name, value}) => {
    //   console.log(38)
    //   return {
    //     name: name,
    //     value
    //   }
    // })
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData(parmas={}) {
      let params = parmas;
      let http =  this.$http.get('/big-screen/district-elevator-quantity');
      http.then(data => {
        const arr =[]
        if (data instanceof Array){
          data.forEach(item=>{
            arr.push({
              name:item.districtName,
              value:item.totalQuantity,
              code:item.districtCode
            })
          })
        }
        this.testData = arr
      console.log(data,38)
        // }
        // this.status = "finish";
      }).catch(() => {
        // this.status = "error";
      });
    },
    mapClick({ code, name }) {
      // mapDict.getRegionCode(name)

      // if (name.indexOf('山东')>-1){
      //   this.getData()
      // }else {
      //   const num = getRegionCode(this.getCityName(name))
      //   const districtCode = num.substr(0,4)
      //   this.getData({districtCode})
      // }

    },
    /**
     *@description 这个方法用来获取城市的简称，例如 北京市 => 北京。 内蒙古自治区 => 内蒙古
     */
    getCityName(cityName) {
      if (!cityName) return;
      let isAutonomy = cityName.search("自治区") != -1;
      if (isAutonomy) {
        return cityName.search("内") != -1
            ? cityName.substr(0, 3)
            : cityName.substr(0, 2);
      } else {
        return cityName.match(/(\S*)(省|市|特别行政区)/)
            ? cityName.match(/(\S*)(省|市|特别行政区)/)[1]
            : cityName;
      }
      // console.log('list2', this.list)
    },
  },
};
</script>
<style>
#app {
  /*background-color: #0f1e33;*/
}
</style>
